import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IMG_URL } from '../configs/app-global';
import galleryService from '../services/gallery';

export default function CkeEditor({ form, lang, languages, label, fieldIndex }) {
  const { t } = useTranslation();

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            const maxSizeMB = 2; // Maximum file size in megabytes

            if (file.size / 1024 / 1024 > maxSizeMB) {
              reject(new Error('File size exceeds the maximum allowed.'));
              return;
            }

            formData.append('image', file);
            formData.append('type', 'blogs');
            galleryService
                .upload(formData)
                .then(({ data }) => {
                  resolve({
                    default: `${IMG_URL + data.title}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleChange = (e, editor) => {
    const data = editor.getData();
    console.log(data);
  };

  return (
      <React.Fragment>
        {languages.map((item) => (
            <Form.Item
                key={fieldIndex + item.locale}
                label={t(label)}
                name={`${fieldIndex}[${item.locale}]`}
                valuePropName='data'
                getValueFromEvent={(event, editor) => {
                  const data = editor.getData();
                  return data;
                }}
                rules={[]}
                hidden={item.locale !== lang}
            >
              <CKEditor
                  editor={ClassicEditor}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onChange={handleChange}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    form.setFieldsValue({
                      [`${fieldIndex}[${item.value}]`]: data,
                    });
                  }}
              />
            </Form.Item>
        ))}
      </React.Fragment>
  );
}
