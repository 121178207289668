import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from 'antd';
import { DebounceSelect } from '../../components/search';
import TextArea from 'antd/es/input/TextArea';
import shopService from '../../services/shop';
import brandService from '../../services/brand';
import categoryService from '../../services/category';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ImageGallery from '../../components/image-gallery';
import productService from '../../services/product';
import { replaceMenu, setMenuData } from '../../redux/slices/menu';
import unitService from '../../services/unit';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncTreeSelect } from '../../components/async-tree-select';
import { useTranslation } from 'react-i18next';
import Description from './description';
import CkeEditor from '../../components/ckeEditor';
import CkeEditor2 from '../../components/ckeEditor2';
import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { zip } from 'lodash';

const ProductsIndex = ({ next, showRichEditor, showEditDigitalOption, editFile, editPageDigitalInfo, showEditTopSell, showEditFeatured }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();


  const {
    settings: { product_auto_approve },
  } = useSelector((state) => state.globalSettings, shallowEqual);
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { defaultLang, languages } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  const [fileList, setFileList] = useState(activeMenu.data?.images || []);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      console.log('aaaaaaaaaaaaaaa', data)
      dispatch(
        setMenuData({ activeMenu, data: { ...activeMenu.data, ...data } })
      );
    };
  }, []);

  async function fetchUserShopList(search) {
    const params = { search };
    return shopService.search(params).then((res) =>
      res.data.map((item) => ({
        label: item.translation ? item.translation.title : 'no name',
        value: item.id,
      }))
    );
  }

  async function fetchUserBrandList(username) {
    return brandService.search(username).then((res) =>
      res.data.map((item) => ({
        label: item.title,
        value: item.id,
      }))
    );
  }

  async function fetchUserCategoryList() {
    const params = { perPage: 100 };
    return categoryService.getAll(params).then((res) =>
      res.data.map((item) => ({
        title: item.translation?.title,
        value: item.id,
        key: item.id,
        disabled: item.children.length ? true : false,
        children: item.children?.map((el) => ({
          title: el.translation?.title,
          value: el.id,
          key: el.id,
          disabled: el.children.length ? true : false,
          children: el.children?.map((three) => ({
            title: three.translation?.title,
            value: three.id,
            key: three.id,
            disabled: three.children.length ? true : false,
          })),
        })),
      }))
    );
  }

  // start zip file upload section

  const [zipFile, setZipFile] = useState(null)


  const [fileList_d, setFileList_d] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    if (editFile) {
      handleDeleteFile()
      formData.append("product_id", editPageDigitalInfo.product_id)
    }

    fileList_d.forEach((file) => {
      formData.append('d_file', file);
      formData.append('type', 'digital-file');
    });

    setUploading(true);
    // You can use any AJAX library you like

    fetch('https://api.thatsgoatmoves.com/api/v1/dashboard/admin/files', {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("ami khujtesi", data)
        setZipFile(data.data.title)
        setFileList_d([]);
        message.success('upload successfully.');

      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props = {
    onRemove: (file) => {
      const index = fileList_d.indexOf(file);
      const newFileList = fileList_d.slice();
      newFileList.splice(index, 1);
      setFileList_d(newFileList);
      // 
    },
    beforeUpload: (file) => {

      const fileName = file.name.split('.')[1];
      // console.log(("fileeeeeee", fileName));
      if (fileName === 'zip') {
        setFileList_d([...fileList_d, file]);
        setShowDelete(null)
        return false;
      }
      else {
        alert(`Please a upload zip file not a ${fileName}`)
      }

    },
    fileList_d,
  };


  // service and digital product optio
  const [showEditor, setShowEditor] = useState(
    showRichEditor ? showRichEditor : false
  );

  // useEffect(() => {
  //   console.log(showEditor);
  // }, [showEditor])

  const handleShowEditor = (e) => {
    setShowEditor(e);
    if (e) {
      setShowDigitalOption(false)
    }

  }

  const [showDigitalOption, setShowDigitalOption] = useState(
    showEditDigitalOption ? showEditDigitalOption : false
  )
  const handleDigitalProductOPtion = (e) => {
    setShowDigitalOption(e)
    if (e) {
      setShowEditor(false);
    }
  }

  const [showTopSell, setShowTopSell] = useState((
    showEditTopSell ? showEditTopSell : false
  ))

  const [showFeatured, setShowFeatured] = useState((
    showEditFeatured ? showEditFeatured : false
  ))


  const onFinish = (values) => {
    setLoadingBtn(true);

    // // zip file
    // if (showDigitalOption) {
    //   if (zipFile) {
    //     return values.d_file = zipFile;
    //   }
    //   else {
    //     return alert('Upload File')
    //   }
    // }


    const params = {
      ...values,
      active: Number(values.active),
      is_service: Number(values.is_service),
      is_digital_product: Number(values.is_digital_product),
      is_featured: Number(values.is_featured),
      is_top_sell: Number(values.is_top_sell),
      brand_id: values.brand?.value,
      category_id: values.category?.value,
      shop_id: values.shop?.value,
      unit_id: values.unit?.value,
      d_file: zipFile || editFile,
      images: undefined,
      brand: undefined,
      category: undefined,
      shop: undefined,
      unit: undefined,
      // d_file: zipFile,

      product_auto_approve: Boolean(Number(product_auto_approve)) || false,
      ...Object.assign(
        {},
        ...fileList.map((item, index) => ({
          [`images[${index}]`]: item.name,
        }))
      ),
    };
    console.log('params => ', params);

    if (showDigitalOption || showEditDigitalOption) {
      if (!params.d_file) {
        setLoadingBtn(false)
        return alert("upload file")
      }
      else {
        if (uuid) {
          productUpdate(values, params);
        } else {
          productCreate(values, params);
        }
      }
    }
    else {
      if (uuid) {
        productUpdate(values, params);
      } else {
        productCreate(values, params);
      }
    }


    // if (uuid) {
    //   productUpdate(values, params);
    // } else {
    //   productCreate(values, params);
    // }

    // zip file
    // if ((showDigitalOption && zipFile) || !showDigitalOption) {
    //   const params = {
    //     ...values,
    //     active: Number(values.active),
    //     is_service: Number(values.is_service),
    //     is_digital_product: Number(values.is_digital_product),
    //     is_featured: Number(values.is_featured),
    //     is_top_sell: Number(values.is_top_sell),
    //     brand_id: values.brand?.value,
    //     category_id: values.category?.value,
    //     shop_id: values.shop?.value,
    //     unit_id: values.unit?.value,
    //     d_file: zipFile,
    //     images: undefined,
    //     brand: undefined,
    //     category: undefined,
    //     shop: undefined,
    //     unit: undefined,
    //     // d_file: zipFile,

    //     product_auto_approve: Boolean(Number(product_auto_approve)) || false,
    //     ...Object.assign(
    //       {},
    //       ...fileList.map((item, index) => ({
    //         [`images[${index}]`]: item.name,
    //       }))
    //     ),
    //   };
    //   console.log('params => ', params);
    //   if (uuid) {
    //     productUpdate(values, params);
    //   } else {
    //     productCreate(values, params);
    //   }
    // }
    // else {
    //   setLoadingBtn(false)
    //   return alert("upload file")
    // }

  };

  function productCreate(values, params) {
    productService
      .create(params)
      .then(({ data }) => {
        dispatch(
          replaceMenu({
            id: `product-${data.uuid}`,
            url: `product/${data.uuid}`,
            name: t('add.product'),
            data: values,
            refetch: false,
          })
        );
        navigate(`/product/${data.uuid}?step=1`);
      })
      .finally(() => setLoadingBtn(false));
  }

  function productUpdate(values, params) {
    productService
      .update(uuid, params)
      .then(({ data }) => {
        dispatch(
          setMenuData({
            activeMenu,
            data: values,
          })
        );
        next();
      })
      .finally(() => setLoadingBtn(false));
  }

  function fetchUnits() {
    const params = {
      perPage: 100,
      page: 1,
      active: 1,
    };
    unitService.getAll(params).then(({ data }) => setUnits(formatUnits(data)));
  }

  useEffect(() => {
    fetchUnits();
  }, []);

  function formatUnits(data) {
    return data.map((item) => ({
      label: item.translation?.title,
      value: item.id,
    }));
  }


  const handleDownloadFile = () => {

    fetch(`https://api.thatsgoatmoves.com/api/v1/dashboard/files/download?file=${editPageDigitalInfo.file}&digital_product=1&name=${editPageDigitalInfo.name}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      // headers: { 'authorization': `Bearer ${localStorage.getItem('token')}`, },
      // body: JSON.stringify(editPageDigitalInfo),
    })
      .then((res) => res.blob())
      .then((data) => {
        console.log(data);
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'digital_file.zip';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      })
    // .finally(() => {

    // });

  }


  // delete and download btn handle
  const [showDelete, setShowDelete] = useState(editFile)

  const handleDeleteFile = () => {
    setShowDelete(null)

    fetch(`https://api.thatsgoatmoves.com/api/v1/dashboard/files/delete?file=${editPageDigitalInfo.file}&digital_product=1&product_id=${editPageDigitalInfo.product_id}`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      // headers: { 'authorization': `Bearer ${localStorage.getItem('token')}`, },
      // body: JSON.stringify(editPageDigitalInfo),
    })
      .then((res) => res.json())
      .then((data) => {

        console.log("response delete data", data);
        if (data.status == true) {
          alert("success delete")
        }
        // else {
        //   alert('failed delete')
        // }

      })
      .catch((err) => {
        console.log(err);
      })
  }


  // console.log("digital", showDigitalOption, "show editor", showEditor)


  return (
    <Form
      layout='vertical'
      form={form}
      initialValues={{
        active: true,
        is_service: false,
        is_digital_product: false,
        is_top_sell: false,
        is_featured: false,
        ...activeMenu.data
      }}
      onFinish={onFinish}
    >
      <Row gutter={12}>

        <Col span={2}>
          {/* service switch btn */}
          <Form.Item
            label={t('Service')}
            name='is_service'
          // valuePropName={showEditor && 'checked'}
          >
            <Switch checked={showEditor} onClick={handleShowEditor} />
          </Form.Item>
        </Col>

        {/* Digital switch btn */}
        <Col span={22}>
          <Form.Item
            label={t('Digital Product')}
            name='is_digital_product'
          // valuePropName={showDigitalOption && 'checked'}
          >
            <Switch checked={showDigitalOption} onClick={handleDigitalProductOPtion} />
          </Form.Item>
        </Col>

        <Col span={12}>
          {languages.map((item) => (
            <Form.Item
              key={'name' + item.id}
              label={t('name')}
              name={`title[${item.locale}]`}
              rules={[
                {
                  required: item.locale === defaultLang,
                  message: t('required'),
                },
              ]}
              hidden={item.locale !== defaultLang}
            >
              <Input />
            </Form.Item>
          ))}
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('category')}
            name='category'
            rules={[{ required: true, message: t('required') }]}
          >
            <AsyncTreeSelect fetchOptions={fetchUserCategoryList} />
          </Form.Item>
        </Col>

        {/* new ckEditor 1 */}
        <Col span={12} className='mb-4'>
          <CkeEditor form={form} languages={languages} lang={defaultLang} label={'Description'} fieldIndex={'description'} />
        </Col>
        {/* new ckEditor 2 */}
        <Col span={12} className='mb-4'>
          <CkeEditor2 form={form} languages={languages} lang={defaultLang} label={showDigitalOption ? "Delivery" : 'Manufacturer Details'} fieldIndex={'manufacturer_details'} />
        </Col>

        {/* <Col span={24}>
          <Form.Item
            label={t('Service')}
            name='is_service'
            valuePropName={showEditor && 'checked'}
          >
            <Switch onClick={handleShowEditor} />
          </Form.Item>
        </Col> */}

        {/* service input field */}
        <Col span={12} className='mb-4' style={showEditor ? { display: 'block' } : { display: 'none' }}>
          <CkeEditor2 form={form} languages={languages} lang={defaultLang} label={'Service Details'} fieldIndex={'service_details'} />
        </Col>

        <Col span={12} className='mb-4' style={showEditor ? { display: 'block' } : { display: 'none' }}>
          <CkeEditor2 form={form} languages={languages} lang={defaultLang} label={'Delivery System'} fieldIndex={'delivery_system'} />
        </Col>

        {/* END service input field */}

        {/* digital product input field */}
        {
          showDigitalOption &&
          <Col span={12}>
            <Form.Item
              label={t('File Name')}
              name='d_product_name'
              form={form}
              rules={[{ required: true, message: t('required') }]}
            >
              <Input />
            </Form.Item>
          </Col>

        }


        <Col span={12} style={showDigitalOption ? { display: 'block' } : { display: 'none' }}>

          <Form.Item
            label={t('File')}
            name='d_file'
          // rules={[{ required: true, message: t('required') }]}
          >
            {/* <Input onChange={handleFileUpload} type='file' className='w-100' /> */}
            {/* <Upload {...file_props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload> */}


            <Upload {...props} maxCount={1}>
              <Button icon={<UploadOutlined />}>Select Zip File</Button>
            </Upload>

            {
              showDelete && <>
                <span className='mt-2'>Current File: {activeMenu.data.d_product_name}.zip</span>
                <p className='mt-2'>
                  <Button
                    onClick={handleDeleteFile}
                    type="primary" icon={<DeleteOutlined />} size={12} />
                  <Button
                    onClick={handleDownloadFile} type="primary ml-2" icon={<DownloadOutlined />} size={12} />
                </p>
              </>

            }

            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList_d.length === 0}
              loading={uploading}
              style={{
                marginTop: 16,
              }}
            >
              {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
          </Form.Item>

        </Col>

        {/* END digital product input field */}

        <Col span={12}>
          <Form.Item
            label={t('shop')}
            name='shop'
            rules={[{ required: true, message: t('required') }]}
          >
            <DebounceSelect
              fetchOptions={fetchUserShopList}
              disabled={uuid ? true : false}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t('brand')}
            name='brand'
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <DebounceSelect fetchOptions={fetchUserBrandList} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t('tax')}
            name='tax'
            rules={[{ required: true, message: t('required') }]}
          >
            <InputNumber min={0} className='w-100' addonAfter='%' />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t('unit')}
            name='unit'
            rules={[{ required: true, message: t('required') }]}
          >
            <Select labelInValue={true} filterOption={false} options={units} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t('min.qty')}
            name='min_qty'
            rules={[{ required: true, message: t('required') }]}
          >
            <InputNumber min={0} className='w-100' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t('max.qty')}
            name='max_qty'
            rules={[{ required: true, message: t('required') }]}
          >
            <InputNumber min={0} className='w-100' />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t('qr.code')}
            name='bar_code'
            rules={[{ required: true, message: t('required') }]}
          >
            <Input className='w-100' />
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item label={t('active')} name='active' valuePropName='checked'>
            <Switch />
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item label={t('Top Sell')} name='is_top_sell'>
            <Switch checked={showTopSell} onClick={(e) => setShowTopSell(e)} />
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item label={t('Featured')} name='is_featured'>
            <Switch checked={showFeatured} onClick={(e) => setShowFeatured(e)} />
          </Form.Item>
        </Col>

        {/* <Col span={4}>
          <Form.Item
            label={t('Service')}
            name='is_service'
            valuePropName='checked'
          >
            <Switch onClick={() => setShowEditor(!showEditor)} />
          </Form.Item>
        </Col> */}

        <Col span={24}>
          <Form.Item
            label={t('images')}
            name='images'
            rules={[{ required: true, message: t('required') }]}
          >
            <ImageGallery
              type='products'
              fileList={fileList}
              setFileList={setFileList}
              form={form}
            />
          </Form.Item>
        </Col>

      </Row>

      <Button type='primary' htmlType='submit' loading={loadingBtn}>
        {t('next')}
      </Button>
    </Form>
  );
};

export default ProductsIndex;
