export const data = {
    admin: [
        {
            name: 'dashboard',
            icon: 'dashboard',
            url: 'dashboard',
            id: 1,
        },
        {
            name: 'shops',
            icon: 'shop',
            url: 'shops',
            id: 2,
        },
        // {
        //   name: 'pos.system',
        //   icon: 'laptop',
        //   url: 'pos-system',
        //   id: 4,
        // },
        {
            name: 'report',
            icon: 'report',
            url: 'report',
            id: 'report',
            children: [
                {
                    name: 'report.products',
                    icon: 'products',
                    url: 'report/products',
                    id: 'report-products',
                },
                {
                    name: 'report.orders',
                    icon: 'orders',
                    url: 'report/orders',
                    id: 'report-orders',
                },
                {
                    name: 'report.stock',
                    icon: 'stock',
                    url: 'report/stock',
                    id: 'report-stock',
                },
                {
                    name: 'report.categories',
                    icon: 'categories',
                    url: 'report/categories',
                    id: 'report-categories',
                },
                {
                    name: 'report.variation',
                    icon: 'variation',
                    url: 'report/variation',
                    id: 'report-variation',
                },
                {
                    name: 'report.revenue',
                    icon: 'revenue',
                    url: 'report/revenue',
                    id: 'report-revenue',
                },
                {
                    name: 'report.overview',
                    icon: 'overview',
                    url: 'report/overview',
                    id: 'report-overview',
                },
                {
                    name: 'shops',
                    icon: 'shop',
                    url: 'report/shops',
                    id: 'report-shops',
                },
            ],
        },
        {
            name: 'catalog',
            icon: 'gold',
            url: 'catalog',
            id: 8,
            children: [
                {
                    icon: 'dropbox',
                    url: 'catalog/products',
                    name: 'products',
                    id: 6,
                },
                {
                    icon: 'branches',
                    url: 'catalog/extras',
                    name: 'extras',
                    id: 7,
                },
                {
                    name: 'categories',
                    icon: 'appStore',
                    url: 'catalog/categories',
                    id: 5,
                },
                {
                    name: 'brands',
                    icon: 'appStoreAdd',
                    url: 'catalog/brands',
                    id: 3,
                },
                {
                    name: 'units',
                    icon: 'disconnect',
                    url: 'catalog/units',
                    id: 26,
                },
            ],
        },
        {
            name: 'orders',
            icon: 'fiShoppingCart',
            url: 'orders',
            id: 14,
        },
        {
            name: 'transactions',
            icon: 'transaction',
            url: 'transactions',
            id: 'transactions',
        },

        {
            name: 'banners',
            icon: 'bsImage',
            url: 'banners',
            id: 10,
        },
        {
            name: 'reviews',
            icon: 'star',
            url: 'reviews',
            id: 'reviews',
            children: [
                {
                    name: 'product.reviews',
                    icon: 'skin',
                    url: 'reviews/product',
                    id: 'product-review',
                },
                {
                    name: 'order.reviews',
                    icon: 'book',
                    url: 'reviews/order',
                    id: 'order-review',
                },
            ],
        },
        {
            name: 'tickets',
            icon: 'project',
            url: 'tickets',
            id: 24,
        },
        {
            name: 'wallets',
            icon: 'wallet',
            url: 'wallets',
            id: 'wallets',
        },
        {
            name: 'payout.requests',
            icon: 'dollar',
            url: 'payout-requests',
            id: 'payout-requests',
        },
        {
            name: 'notifications',
            icon: 'notificationsActive',
            url: 'notifications',
            id: 11,
        },
        {
            name: 'subscriptions',
            icon: 'imSubscript',
            url: 'subscriptions',
            id: 13,
        },
        {
            name: 'cashback',
            icon: 'copyright',
            url: 'cashback',
            id: 'cashback',
        },
        {
            name: 'coupons',
            icon: 'calendar',
            url: 'coupons',
        },
        // {
        //   name: 'delivery_of_country',
        //   icon: 'dollar',
        //   url: 'delivery-of-country',
        //   id: 'delivery_of_country',
        // },
        {
            name: 'delivery',
            icon: 'deliveryDining',
            id: 'delivery',
            url: 'delivery',
            children: [
                {
                    icon: 'deliveryDining',
                    name: 'delivery',
                    url: 'delivery/list',
                    id: 15,
                },
                {
                    icon: 'deliveryDining',
                    name: 'deliveryman',
                    url: 'delivery/deliveryman',
                    id: 16,
                },
            ],
        },
        /*{
            name: 'gallery',
            url: 'gallery',
            icon: 'fiImage',
            id: 17,
        },*/
        {
            name: 'users',
            id: 'user',
            icon: 'user',
            url: 'users',
            children: [
                {
                    icon: 'user',
                    url: 'users/user',
                    name: 'clients',
                    id: 18,
                },
                {
                    icon: 'userGroupAdd',
                    url: 'users/admin',
                    name: 'users',
                    id: 19,
                },
                {
                    icon: 'userSwitch',
                    url: 'users/role',
                    name: 'roles',
                    id: 20,
                },
            ],
        },
        {
            name: 'blogs',
            url: 'blogs',
            icon: 'form',
            id: 'blogs',
        },
        {
            name: 'settings',
            icon: 'settings',
            url: 'settings',
            id: 9,
            children: [
                {
                    icon: 'settings',
                    url: 'settings/general',
                    name: 'settings.general',
                    id: 21,
                },
                {
                    icon: 'global',
                    url: 'settings/languages',
                    name: 'languages',
                    id: 22,
                },
                {
                    icon: 'moneyCollect',
                    url: 'currencies',
                    name: 'currencies',
                    id: 12,
                },
                {
                    icon: 'translation',
                    url: 'settings/translations',
                    name: 'translations',
                    id: 23,
                },
                {
                    icon: 'database',
                    url: 'settings/backup',
                    name: 'backup',
                    id: 24,
                },
                {
                    icon: 'tool',
                    url: 'settings/system-information',
                    name: 'system.information',
                    id: 25,
                },
                {
                    icon: 'wallet',
                    url: 'settings/payments',
                    name: 'payments',
                    id: 'payments',
                },
                {
                    icon: 'message',
                    url: 'settings/sms-gateways',
                    name: 'sms-gateways',
                    id: 'sms-gateways',
                },
                {
                    icon: 'questionCircle',
                    url: 'settings/faqs',
                    name: 'FAQ',
                    id: 'faq',
                },
                {
                    icon: 'paperClip',
                    url: 'settings/terms',
                    name: 'terms',
                    id: 'terms',
                },
                {
                    icon: 'lock',
                    url: 'settings/policy',
                    name: 'policy',
                    id: 'policy',
                },
                {
                    icon: 'cloudUpload',
                    url: 'settings/update',
                    name: 'update',
                    id: 'update',
                },
                {
                    icon: 'fire',
                    url: 'settings/firebase',
                    name: 'firebase.config',
                    id: 'firebase-config',
                },
                {
                    icon: 'instagram',
                    url: 'settings/social',
                    name: 'social.settings',
                    id: 'social-settings',
                },
            ],
        },
        {
            name: 'logout',
            icon: 'logout',
            id: 'logout',
        },
    ],
    manager: [
        {
            name: 'dashboard',
            icon: 'dashboard',
            url: 'dashboard',
            id: 1,
        },
        {
            name: 'shops',
            icon: 'shop',
            url: 'shops',
            id: 2,
        },
        {
            name: 'catalog',
            icon: 'gold',
            url: 'catalog',
            id: 8,
            children: [
                {
                    icon: 'dropbox',
                    url: 'catalog/products',
                    name: 'products',
                    id: 6,
                },
                {
                    icon: 'branches',
                    url: 'catalog/extras',
                    name: 'extras',
                    id: 7,
                },
                {
                    name: 'categories',
                    icon: 'appStore',
                    url: 'catalog/categories',
                    id: 5,
                },
                {
                    name: 'brands',
                    icon: 'appStoreAdd',
                    url: 'catalog/brands',
                    id: 3,
                },
                {
                    name: 'units',
                    icon: 'disconnect',
                    url: 'catalog/units',
                    id: 26,
                },
            ],
        },
        {
            name: 'orders',
            icon: 'fiShoppingCart',
            url: 'orders',
            id: 14,
        },
        {
            name: 'transactions',
            icon: 'transaction',
            url: 'transactions',
            id: 'transactions',
        },
        // {
        //   name: 'pos.system',
        //   icon: 'laptop',
        //   url: 'pos-system',
        //   id: 4,
        // },
        {
            name: 'banners',
            icon: 'bsImage',
            url: 'banners',
            id: 10,
        },
        {
            name: 'reviews',
            icon: 'star',
            url: 'reviews',
            id: 'reviews',
            children: [
                {
                    name: 'product.reviews',
                    icon: 'skin',
                    url: 'reviews/product',
                    id: 'product-review',
                },
                {
                    name: 'order.reviews',
                    icon: 'book',
                    url: 'reviews/order',
                    id: 'order-review',
                },
            ],
        },
        {
            name: 'tickets',
            icon: 'project',
            url: 'tickets',
            id: 24,
        },
        {
            name: 'wallets',
            icon: 'wallet',
            url: 'wallets',
            id: 'wallets',
        },
        {
            name: 'payout.requests',
            icon: 'dollar',
            url: 'payout-requests',
            id: 'payout-requests',
        },
        {
            name: 'notifications',
            icon: 'notificationsActive',
            url: 'notifications',
            id: 11,
        },
        {
            name: 'subscriptions',
            icon: 'imSubscript',
            url: 'subscriptions',
            id: 13,
        },
        {
            name: 'delivery',
            icon: 'deliveryDining',
            id: 'delivery',
            url: 'delivery',
            children: [
                {
                    icon: 'deliveryDining',
                    name: 'delivery',
                    url: 'delivery/list',
                    id: 15,
                },
                {
                    icon: 'deliveryDining',
                    name: 'deliveryman',
                    url: 'delivery/deliveryman',
                    id: 16,
                },
            ],
        },
        {
            name: 'users',
            id: 'user',
            icon: 'user',
            url: 'users',
            children: [
                {
                    icon: 'user',
                    url: 'users/user',
                    name: 'clients',
                    id: 18,
                },
                {
                    icon: 'userGroupAdd',
                    url: 'users/admin',
                    name: 'users',
                    id: 19,
                },
                {
                    icon: 'userSwitch',
                    url: 'users/role',
                    name: 'roles',
                    id: 20,
                },
            ],
        },
        {
            name: 'blogs',
            url: 'blogs',
            icon: 'form',
            id: 'blogs',
        },
        {
            name: 'settings',
            icon: 'settings',
            url: 'settings',
            id: 9,
            children: [
                {
                    icon: 'settings',
                    url: 'settings/general',
                    name: 'settings.general',
                    id: 21,
                },
                {
                    icon: 'global',
                    url: 'settings/languages',
                    name: 'languages',
                    id: 22,
                },
                {
                    icon: 'moneyCollect',
                    url: 'currencies',
                    name: 'currencies',
                    id: 12,
                },
                {
                    icon: 'translation',
                    url: 'settings/translations',
                    name: 'translations',
                    id: 23,
                },
                {
                    icon: 'database',
                    url: 'settings/backup',
                    name: 'backup',
                    id: 24,
                },
                {
                    icon: 'tool',
                    url: 'settings/system-information',
                    name: 'system.information',
                    id: 25,
                },
                {
                    icon: 'wallet',
                    url: 'settings/payments',
                    name: 'payments',
                    id: 'payments',
                },
                {
                    icon: 'message',
                    url: 'settings/sms-gateways',
                    name: 'sms-gateways',
                    id: 'sms-gateways',
                },
                {
                    icon: 'questionCircle',
                    url: 'settings/faqs',
                    name: 'FAQ',
                    id: 'faq',
                },
                {
                    icon: 'paperClip',
                    url: 'settings/terms',
                    name: 'terms',
                    id: 'terms',
                },
                {
                    icon: 'lock',
                    url: 'settings/policy',
                    name: 'policy',
                    id: 'policy',
                },
                {
                    icon: 'cloudUpload',
                    url: 'settings/update',
                    name: 'update',
                    id: 'update',
                },
                {
                    icon: 'fire',
                    url: 'settings/firebase',
                    name: 'firebase.config',
                    id: 'firebase-config',
                },
                {
                    icon: 'instagram',
                    url: 'settings/social',
                    name: 'social.settings',
                    id: 'social-settings',
                },
            ],
        },
        {
            name: 'logout',
            icon: 'logout',
            id: 'logout',
        },
    ],
    seller: [
        {
            name: 'dashboard',
            icon: 'dashboard',
            url: 'dashboard',
        },
        // {
        //   name: 'pos.system',
        //   icon: 'laptop',
        //   url: 'seller/pos-system',
        //   id: 'pos-system',
        // },
        {
            name: 'report',
            icon: 'report',
            url: 'seller/report',
            id: 'report',
            children: [
                {
                    name: 'report.overview',
                    icon: 'overview',
                    url: 'seller/report/overview',
                    id: 'report-overview',
                },
                {
                    name: 'report.products',
                    icon: 'products',
                    url: 'seller/report/products',
                    id: 'report-products',
                },
                {
                    name: 'report.revenue',
                    icon: 'revenue',
                    url: 'seller/report/revenue',
                    id: 'report-revenue',
                },
                {
                    name: 'report.orders',
                    icon: 'orders',
                    url: 'seller/report/orders',
                    id: 'report-orders',
                },
                {
                    name: 'report.variation',
                    icon: 'variation',
                    url: 'seller/report/variation',
                    id: 'report-variation',
                },
                {
                    name: 'report.categories',
                    icon: 'categories',
                    url: 'seller/report/categories',
                    id: 'report-categories',
                },
                {
                    name: 'report.stock',
                    icon: 'stock',
                    url: 'seller/report/stock',
                    id: 'report-stock',
                },
            ],
        },
        {
            name: 'subscriptions',
            icon: 'trophy',
            url: 'seller/subscriptions',
            id: 'subscription',
        },
        {
            icon: 'dropbox',
            url: 'seller/products',
            name: 'Products',
            id: 6,
        },
        {
            name: 'categories',
            icon: 'appStore',
            url: 'seller/categories',
            id: 5,
        },
        {
            name: 'brands',
            icon: 'appStoreAdd',
            url: 'seller/brands',
            id: 3,
        },
        {
            name: 'my.shop',
            icon: 'shop',
            id: 'my-shop',
            url: 'my-shop',
        },
        {
            name: 'orders',
            icon: 'fiShoppingCart',
            url: 'seller/orders',
            id: 'orders',
        },
        {
            name: 'looks',
            icon: 'star',
            url: 'seller/looks',
            id: 'looks',
        },
        // {
        //   name: 'coupons',
        //   icon: 'calendar',
        //   url: 'coupons',
        // },
        {
            name: 'discounts',
            icon: 'euroCircle',
            url: 'seller/discounts',
        },
        {
            name: 'invites',
            icon: 'orderedList',
            url: 'seller/invites',
        },
        {
            name: 'payouts',
            icon: 'dollar',
            url: 'seller/payouts',
        },
        {
            name: 'delivery',
            icon: 'deliveryDining',
            id: 'delivery',
            url: 'delivery',
            children: [
                {
                    icon: 'deliveryDining',
                    name: 'delivery',
                    url: 'seller/delivery/list',
                    id: 15,
                },
                // {
                //   icon: 'deliveryDining',
                //   name: 'deliveryman',
                //   url: 'seller/delivery/deliveryman',
                //   id: 16,
                // },
            ],
        },
        {
            name: 'users',
            id: 'users',
            icon: 'user',
            url: 'seller/shop-users',
        },
        {
            name: 'logout',
            icon: 'logout',
            id: 'logout',
        },
    ],
    moderator: [
        {
            name: 'dashboard',
            icon: 'dashboard',
            url: 'dashboard',
        },
        // {
        //   name: 'pos.system',
        //   icon: 'laptop',
        //   url: 'seller/pos-system',
        //   id: 'pos-system',
        // },
        {
            icon: 'dropbox',
            url: 'seller/products',
            name: 'Products',
            id: 6,
        },
        {
            name: 'categories',
            icon: 'appStore',
            url: 'seller/categories',
            id: 5,
        },
        {
            name: 'brands',
            icon: 'appStoreAdd',
            url: 'seller/brands',
            id: 3,
        },
        {
            name: 'my.shop',
            icon: 'shop',
            id: 'my-shop',
            url: 'my-shop',
        },
        {
            name: 'orders',
            icon: 'fiShoppingCart',
            url: 'seller/orders',
            id: 'orders',
        },
        {
            name: 'looks',
            icon: 'star',
            url: 'seller/looks',
            id: 'looks',
        },
        {
            name: 'discounts',
            icon: 'euroCircle',
            url: 'seller/discounts',
        },
        {
            name: 'logout',
            icon: 'logout',
            id: 'logout',
        },
    ],
    deliveryman: [
        {
            name: 'dashboard',
            icon: 'dashboard',
            url: 'dashboard',
            id: 'dashboard',
        },
        {
            name: 'orders',
            icon: 'fiShoppingCart',
            url: 'deliveryman/orders',
            id: 'orders',
        },
        {
            name: 'logout',
            icon: 'logout',
            id: 'logout',
        },
    ],
    user: [
        {
            name: 'dashboard',
            icon: 'dashboard',
            url: 'dashboard',
            id: 1,
        },
        {
            name: 'Shops',
            icon: 'shop',
            id: 'shops',
        },
        {
            name: 'Brand',
            icon: 'appStoreAdd',
            id: 'brand',
        },
        // {
        //   name: 'POS System',
        //   icon: 'laptop',
        //   url: 'pos-system',
        //   id: 'pos-system',
        // },
        {
            name: 'user',
            icon: 'moneyCollect',
            id: 'user',
        },
        {
            name: 'logout',
            icon: 'logout',
            id: 'logout',
        },
    ],
};
